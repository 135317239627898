





















import { Vue, Component } from "vue-property-decorator";
import Transactions from "@/store/transactions";
import FancyButton from "@/components/FancyButton.vue";

@Component({
  components: {
    FancyButton
  }
})
export default class extends Vue {
  headers = [
    { text: "Transaction ID", value: "transactionId" },
    { text: "Subscriber", value: "subscriber" },
    { text: "Date", value: "issuedAt" }
  ];

  get transactions() {
    return Transactions.transactions;
  }

  get loading() {
    return Transactions.loading;
  }

  get error() {
    return Transactions.error;
  }

  async mounted() {
    this.refresh();
  }

  async refresh() {
    await Transactions.fetchAll();
  }

  reload() {
    this.refresh();
    this.$forceUpdate();
  }
}
