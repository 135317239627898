import Vue from 'vue';
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from './store';
import { Subscriber } from './subscribers';

export type Transaction = {
  _id: string;
  transactionId: string;
  subscriber: Subscriber;
  issuedAt: Date;
}

@Module({ namespaced: true, name: 'transactions', dynamic: true, store, preserveState: true })
class Transactions extends VuexModule {
  transactions: Transaction[] = [];
  error = '';
  loading = false;

  @Mutation
  setTransactions(payload: Transaction[]) {
    this.transactions = payload;
  }

  @Mutation
  setError(payload: string) {
    this.error = payload;
  }

  @Mutation
  setLoading(payload: boolean) {
    this.loading = payload;
  }

  @Mutation
  resetState() {
    this.transactions = [];
    this.error = '';
    this.loading = false;
  }

  @Action
  async fetchAll() {
    this.setLoading(true);
    this.setError('');
    try {
      const response = await Vue.$axios.get('/transactions');
      this.setTransactions(response.data);
    } catch (err) {
      this.setError(err.message || "Cannot fetch transactions");
    }
    this.setLoading(false);
  }

  @Action
  async resetStateAction() {
    this.resetState();
  }
}

export default getModule(Transactions);
